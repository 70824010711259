import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./Layout.module.css";
import LeftSideNav from '../../LeftSideNav/LeftSideNav';
import {Container, Row, Col} from 'react-bootstrap';

class Layout extends Component {
  state = {};

  render() {
    return (
      <Container fluid>
        <Row>
          <Col className={styles.leftSideBar}>
            <LeftSideNav />
          </Col>
          <Col className={styles.mainContent}>
            <div className={styles.mainContentBackground}>
              {this.props.children}
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default connect()(Layout);
